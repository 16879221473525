import { isNil } from 'ramda';
import { useSelector } from 'react-redux';

import CostTable from './costTable';
import { costForm } from 'features/roboAdvice/adviceSession/proposal/services/costForm';
import Icon from 'features/shared/components/icon';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography, FontWeights } from 'features/shared/constants/typography';
import sessionSelectors from 'features/shared/services/session/selectors';
import { getNumberFromLocaleStringNumber } from 'features/shared/utils/number';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  tableHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: 'calc(3rem + 3px)',
    paddingTop: 30,
    paddingBottom: Spacing.spacing01,
    textAlign: 'left',
    fontWeight: FontWeights.medium,
    fontSize: Typography.heading1,
    lineHeight: '23px'
  },
  editIcon: {
    cursor: 'pointer'
  },
  tableContainer: {
    border: `1px solid transparent`
  },
  tableContainerEditMode: {
    border: `1px solid ${theme.accentColor}`
  }
}));

const FundTableSection = ({
  tableData,
  headerTitles,
  dataKeys,
  sectionHeader,
  highlightLastRow,
  overrideFundCosts,
  isEditModeActive,
  setIsEditModeActive,
  isOverrideFundEnabled,
  originalTableData,
  isAnyEditModeActive
}) => {
  const classes = useStyles();
  const i18n = useI18n();
  const cultureCode = useSelector(sessionSelectors.getCurrentUserCultureCode);

  const handleStartEditCost = () => {
    tableData.forEach(data => {
      Object.entries(data).forEach(([key, value]) => {
        if (
          overrideFundCosts.includes(key) &&
          data.fund !== i18n('portfolioChart.default.portfolio')
        ) {
          costForm.change(
            `overrideFundCosts.${data.fund}.${key}`,
            getNumberFromLocaleStringNumber(value, cultureCode)
          );
        }
      });
    });

    setIsEditModeActive(true);
  };

  return (
    !!tableData?.length &&
    tableData.every(item =>
      dataKeys.some(key => key !== 'fund' && !isNil(item[key]))
    ) && (
      <>
        <div className={classes.tableHeader}>
          <span>{sectionHeader}</span>

          {isOverrideFundEnabled &&
            overrideFundCosts?.length > 0 &&
            !isAnyEditModeActive && (
              <Icon
                className={classes.editIcon}
                type="edit"
                onClick={handleStartEditCost}
              />
            )}
        </div>

        <CostTable
          containerClassName={
            isEditModeActive && overrideFundCosts?.length
              ? classes.tableContainerEditMode
              : classes.tableContainer
          }
          headerTitles={headerTitles}
          data={tableData}
          dataKeys={dataKeys}
          highlightLastRow={highlightLastRow}
          isEditModeActive={isEditModeActive}
          overrideCosts={overrideFundCosts}
          isOverrideEnabled={isOverrideFundEnabled}
          originalTableData={originalTableData}
        />
      </>
    )
  );
};

export default FundTableSection;
