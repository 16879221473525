import { formatDateForAPI } from 'features/shared/utils/dateTime';

export const getDateOfBirthForCostEndpoints = ({
  clientDateOfBirth,
  defaultDateOfBirth
}: {
  clientDateOfBirth: string | null;
  defaultDateOfBirth: string | null;
}) => {
  if (defaultDateOfBirth) {
    return formatDateForAPI(defaultDateOfBirth);
  }

  if (clientDateOfBirth) {
    return formatDateForAPI(clientDateOfBirth);
  }

  return null;
};
