import { useSelector } from 'react-redux';

import { useCostChartStore } from '../../../costChart';
import { usePageStore as useProposalPageStore } from '../../services/pageStore';
import { useInitPage } from './useInitPage';
import { useReadCostChartData } from './useReadCostChartData';
import { useReadMultiGoalCostChartData } from './useReadMultiGoalCostChartData';
import { OverridePlatformFeeModes } from 'defaults/defaultRoboAdviceForm';
import {
  PageStatuses,
  useReadDataListener
} from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';
import CostChartViewVisual from 'features/shared/analyticsComponents/costChart/visual';
import sessionSelectors from 'features/shared/services/session/selectors';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';

const CostChartView = ({ isSelected }) => {
  const cultureCode = useSelector(sessionSelectors.getCurrentUserCultureCode);
  const chartStoreData = useCostChartStore();
  const {
    roboAdviceForm: {
      proposal: {
        isOverrideFundManagementFeeEnabled,
        isOverrideFundTransactionCostEnabled,
        isOverrideFundPurchasingFeeEnabled,
        isOverrideFundReturnCommissionPaidToClientEnabled,
        overridePlatformFeeMode
      }
    },
    analyticsComponents: { multiGoalCostEnabled }
  } = useCustomerConfig();
  const {
    pageStatuses: { readCostChartData }
  } = useProposalPageStore.getState();

  useReadDataListener(
    readCostChartData,
    isSelected && !multiGoalCostEnabled,
    useReadCostChartData()
  );
  useReadDataListener(
    readCostChartData,
    isSelected && multiGoalCostEnabled,
    useReadMultiGoalCostChartData()
  );

  useInitPage();

  return (
    <CostChartViewVisual
      isOverrideFundEnabled={
        isOverrideFundManagementFeeEnabled ||
        isOverrideFundTransactionCostEnabled ||
        isOverrideFundPurchasingFeeEnabled ||
        isOverrideFundReturnCommissionPaidToClientEnabled ||
        overridePlatformFeeMode === OverridePlatformFeeModes.instrument
      }
      chartStoreChartData={chartStoreData.chartData}
      chartStoreTableData={chartStoreData.tableData}
      chartStoreOriginalTableData={chartStoreData.originalTableData}
      cultureCode={cultureCode}
      isLoading={readCostChartData === PageStatuses.pending}
    />
  );
};

export default CostChartView;
