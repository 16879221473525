import classNames from 'classnames';
import React from 'react';

import { useIsSessionReadOnly } from '../../main/services/selectors';
import { getHorizonYearsDisplayValue } from '../../proposal/services/selectors';
import { useSessionStore } from '../../session/services/sessionStore';
import { WithdrawalPlans } from '../../shared/constants';
import { useGoalsStore } from '../../shared/services/goalsStore';
import { GoalModalModeTypes } from '../constants';
import { goalForm } from '../services/goalForm';
import { useDeleteGoal } from './useDeleteGoal';
import { ReactComponent as WarningIcon } from 'assets/warning.svg';
import GoalTableIcon from 'features/shared/components/icon/goalTableIcon';
import Icon from 'features/shared/components/icon/index.js';
import TableComponent from 'features/shared/components/table/table.js';
import TableBody from 'features/shared/components/table/tableBody.js';
import TableBodyRow from 'features/shared/components/table/tableBodyRow.js';
import { Spacing } from 'features/shared/constants/spacing';
import { defaultConfirmation } from 'features/sharedInstances/defaultConfirmation';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  table: {
    maxWidth: '740px'
  },
  goalIconCell: {
    width: Spacing.spacing04,
    textAlign: 'center',
    fill: theme.accentColor,

    '& svg': {
      verticalAlign: 'middle'
    }
  },
  actionButtonsCell: {
    width: Spacing.spacing06,
    textAlign: 'right',
    padding: 0
  },
  tableActionButton: {
    padding: Spacing.spacing01,
    margin: 0,
    cursor: 'pointer'
  },
  goalName: {
    display: 'flex',
    alignItems: 'center'
  },
  infoIcon: {
    rotate: '180deg',
    marginLeft: Spacing.spacing00,

    '& path': {
      fill: theme.warningColor
    }
  }
}));

const GoalTable = ({ setGoalModalMode, setEditGoalId }) => {
  const i18n = useI18n();
  const classes = useStyles();
  const isSessionReadOnly = useIsSessionReadOnly();
  const { goals } = useGoalsStore();
  const deleteGoal = useDeleteGoal();
  const {
    roboAdviceForm: {
      purposeAndRisk: { showGoalIcons }
    },
    analyticsComponents: { isCashflowHidden },
    timeHorizonConfig
  } = useCustomerConfig();
  const sessionStore = useSessionStore();

  const tableClasses = React.useMemo(
    () => ({
      table: classes.table
    }),
    [classes]
  );

  const headers = [
    showGoalIcons ? {} : null,
    { title: i18n('roboAdvice.purposeAndRisk.purposeTable.goal') },
    { title: i18n('roboAdvice.purposeAndRisk.purposeTable.years') },
    isSessionReadOnly ? null : {}
  ].filter(header => header !== null);

  const onDeleteGoalClick = async goalId => {
    await deleteGoal(goalId);
  };

  const onEditGoal = ({ goalId, data, name, description, icon }) => {
    goalForm.batch(() => {
      goalForm.change('name', name);
      goalForm.change('description', description);
      goalForm.change('icon', icon);
      goalForm.change('data.timeHorizon', data.timeHorizon);
      goalForm.change('data.timeHorizonToDisplay', data.timeHorizonToDisplay);
      if (!isCashflowHidden) {
        goalForm.change('data.withdrawalPlan', data.withdrawalPlan);
        if (data.withdrawalPlan === WithdrawalPlans.monthlyWithdrawal) {
          goalForm.change('data.monthlyWithdrawal', data.monthlyWithdrawal);
          goalForm.change('data.withdrawalStart', data.withdrawalStart);
          goalForm.change('data.withdrawalEnd', data.withdrawalEnd);
        }

        if (data.withdrawalPlan === WithdrawalPlans.oneTimeWithdrawal) {
          goalForm.change('data.capitalNeed', data.capitalNeed);
        }
      }
    });
    setEditGoalId(goalId);
    setGoalModalMode(GoalModalModeTypes.edit);
  };

  return (
    <TableComponent header={headers} externalClasses={tableClasses}>
      <TableBody>
        {goals.map(({ name, goalId, icon, description, data }) => {
          const isChangedRiskScoreWarningVisible =
            data.isPortfolioCustom &&
            data.isCustomPortfolioDirty &&
            sessionStore.fieldsWhichChangedRiskScore.some(
              field => field.startsWith('timeHorizon') && field.endsWith(goalId)
            );

          return (
            <TableBodyRow key={goalId}>
              {({ bodyRowCellClassName }) => (
                <>
                  {showGoalIcons && (
                    <td
                      className={classNames(
                        bodyRowCellClassName,
                        classes.goalIconCell
                      )}
                    >
                      <GoalTableIcon name={name} icon={icon} />
                    </td>
                  )}
                  <td className={bodyRowCellClassName}>
                    <div className={classes.goalName}>
                      {name}
                      {isChangedRiskScoreWarningVisible ? (
                        <WarningIcon className={classes.infoIcon} />
                      ) : null}
                    </div>
                  </td>
                  <td className={bodyRowCellClassName}>
                    {getHorizonYearsDisplayValue({
                      timeHorizonConfig,
                      timeHorizon: data.timeHorizon,
                      i18n
                    })}
                  </td>
                  {!isSessionReadOnly && (
                    <td
                      className={classNames(
                        bodyRowCellClassName,
                        classes.actionButtonsCell
                      )}
                    >
                      <Icon
                        className={classes.tableActionButton}
                        onClick={() =>
                          onEditGoal({
                            goalId,
                            name,
                            icon,
                            data,
                            description
                          })
                        }
                        type="edit"
                      />
                      <Icon
                        className={classes.tableActionButton}
                        type="delete"
                        onClick={() => {
                          const defaultConfirmationStore =
                            defaultConfirmation.useConfirmationStore.getState();

                          defaultConfirmationStore.open(
                            i18n(
                              'roboAdvice.purposeAndRisk.purposeTable.deleteConfirmationMessage'
                            ),
                            () => {
                              onDeleteGoalClick(goalId);
                            }
                          );
                        }}
                      />
                    </td>
                  )}
                </>
              )}
            </TableBodyRow>
          );
        })}
      </TableBody>
    </TableComponent>
  );
};

export default GoalTable;
