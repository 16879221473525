import { isNil, lensPath, view } from 'ramda';

import {
  useAmountForAdviceAssetsWithValue,
  useAmountForAdviceValues
} from '../../../roboAdvice/adviceSession/financialSituation/services/selectors';
import {
  AdviceSessionFormValues,
  useForm as useRoboAdviceForm
} from '../../../roboAdvice/adviceSession/form/services/form';
import { ClientTypes } from '../../../shared/constants/session';
import { filterNil } from '../../../shared/utils/filters';
import { getTranslation } from '../../../shared/utils/translations';
import { useCustomerConfig } from '../../customerConfig/components/useCustomerConfig';
import { useI18n } from '../../customerConfig/components/useI18n';
import { ErrorMessage, FinancialSituationErrors } from '../types';
import { isFieldProgressCalculationRequired } from 'features/roboAdvice/adviceSession/shared/services/progressSelectors';

export const useGetFinancialSituationErrors = (): FinancialSituationErrors => {
  const i18n = useI18n();
  const roboAdviceFormState = useRoboAdviceForm();
  const {
    roboAdviceForm: {
      financialSituation: financialSituationConfig,
      errorMessagesEnabled
    },
    advisoryComponents: {
      financialSituation: {
        amountForAdvice: { advisorNotes: amountForAdviceAdvisorNotesConfig }
      }
    }
  } = useCustomerConfig();
  const { currentSum } = useAmountForAdviceValues();
  const amountForAdviceAssetsWithValue = useAmountForAdviceAssetsWithValue();

  return {
    assets: getAssetsErrors({
      roboAdviceFormState,
      financialSituationConfig,
      i18n,
      errorMessagesEnabled
    }),
    debt: getDebtErrors({
      roboAdviceFormState,
      financialSituationConfig,
      i18n,
      errorMessagesEnabled
    }),
    liquidity: getLiquidityErrors({
      roboAdviceFormState,
      financialSituationConfig,
      i18n,
      errorMessagesEnabled
    }),
    accountingFigures: getAccountingFiguresErrors({
      roboAdviceFormState,
      financialSituationConfig,
      i18n,
      errorMessagesEnabled
    }),
    amountForAdvice: getAmountForAdviceErrors({
      roboAdviceFormState,
      financialSituationConfig,
      currentSum,
      i18n,
      errorMessagesEnabled,
      amountForAdviceAssetsWithValue
    }),
    amountForAdviceAdvisoryNotes: getAmountForAdviceAdvisoryNotesErrors({
      roboAdviceFormState,
      financialSituationConfig,
      amountForAdviceAdvisorNotesConfig,
      i18n,
      errorMessagesEnabled
    })
  };
};

const getAssetsErrors = ({
  roboAdviceFormState,
  financialSituationConfig,
  i18n,
  errorMessagesEnabled
}): ErrorMessage[] => {
  const assetsErrors: ErrorMessage[] = [];
  const clientType = roboAdviceFormState?.values?.clientInformation?.clientType;

  const { assets, oneFieldInAssetsRequired } = financialSituationConfig;

  const assetsFields = assets[clientType] || [];

  if (!errorMessagesEnabled) {
    return assetsErrors;
  }

  const key =
    clientType === ClientTypes.company
      ? 'companyFinancialSituation'
      : 'personFinancialSituation';

  if (oneFieldInAssetsRequired) {
    const isAnyFieldFilled = assetsFields
      .filter(({ enabled }) => enabled)
      .some(field => {
        const value = view(
          lensPath([key, field.id]),
          roboAdviceFormState.values
        );
        return value && Array.isArray(value)
          ? value.some(d => filterNil(d?.value))
          : value !== undefined && value !== null;
      });

    if (!isAnyFieldFilled) {
      assetsErrors.push({
        listMessage: i18n('roboAdvice.errors.missingField').replace(
          '{0}',
          i18n('roboAdvice.financialSituation.assets')
        ),
        alertMessage: i18n('roboAdvice.errors.missingFieldAlert').replace(
          '{0}',
          i18n('roboAdvice.financialSituation.assets')
        ),
        elementId:
          clientType === ClientTypes.company
            ? 'company-assets'
            : 'person-assets'
      });
    }
  }

  const fieldsErrors = assetsFields
    .filter(({ enabled, required }) => enabled && required)
    .reduce((prev, field) => {
      const value = view(lensPath([key, field.id]), roboAdviceFormState.values);
      const hasValue =
        value && Array.isArray(value)
          ? value.some(d => filterNil(d?.value))
          : value !== undefined && value !== null;
      if (!hasValue) {
        return [
          ...prev,
          {
            listMessage: i18n('roboAdvice.errors.missingInput').replace(
              '{0}',
              getTranslation(field.label)
            ),
            alertMessage: i18n('roboAdvice.errors.missingInputAlert').replace(
              '{0}',
              getTranslation(field.label)
            ),
            elementId:
              clientType === ClientTypes.company
                ? 'company-assets'
                : 'person-assets'
          }
        ];
      }

      return prev;
    }, []);

  if (fieldsErrors.length > 1) {
    return [
      ...assetsErrors,
      {
        listMessage: i18n('roboAdvice.errors.missingFieldsAssetSection'),
        alertMessage: i18n('roboAdvice.errors.missingFieldsAssetSection'),
        elementId:
          clientType === ClientTypes.company
            ? 'company-assets'
            : 'person-assets'
      }
    ];
  }

  return [...assetsErrors, ...fieldsErrors];
};

const getDebtErrors = ({
  roboAdviceFormState,
  financialSituationConfig,
  i18n,
  errorMessagesEnabled
}): ErrorMessage[] => {
  const debtErrors: ErrorMessage[] = [];
  const clientType = roboAdviceFormState?.values?.clientInformation?.clientType;

  const { debt, oneFieldInDebtRequired } = financialSituationConfig;

  const debtFields = debt[clientType] || [];

  if (!errorMessagesEnabled) {
    return debtErrors;
  }

  const key =
    clientType === ClientTypes.company
      ? 'companyFinancialSituation'
      : 'personFinancialSituation';

  if (oneFieldInDebtRequired) {
    const isAnyFieldFilled = debtFields
      .filter(({ enabled }) => enabled)
      .some(field => {
        const value = view(
          lensPath([key, field.id]),
          roboAdviceFormState.values
        );
        return value && Array.isArray(value)
          ? value.some(d => filterNil(d?.value))
          : value !== undefined && value !== null;
      });

    if (!isAnyFieldFilled) {
      debtErrors.push({
        listMessage: i18n('roboAdvice.errors.missingField').replace(
          '{0}',
          i18n('roboAdvice.financialSituation.debt')
        ),
        alertMessage: i18n('roboAdvice.errors.missingFieldAlert').replace(
          '{0}',
          i18n('roboAdvice.financialSituation.debt')
        ),
        elementId:
          clientType === ClientTypes.company ? 'company-debt' : 'person-debt'
      });
    }
  }

  const fieldsErrors = debtFields
    .filter(({ enabled, required }) => enabled && required)
    .reduce((prev, field) => {
      const value = view(lensPath([key, field.id]), roboAdviceFormState.values);
      const hasValue =
        value && Array.isArray(value)
          ? value.some(d => filterNil(d?.value))
          : value !== undefined && value !== null;
      if (!hasValue) {
        return [
          ...prev,
          {
            listMessage: i18n('roboAdvice.errors.missingInput').replace(
              '{0}',
              getTranslation(field.label)
            ),
            alertMessage: i18n('roboAdvice.errors.missingInputAlert').replace(
              '{0}',
              getTranslation(field.label)
            ),
            elementId:
              clientType === ClientTypes.company
                ? 'company-debt'
                : 'person-debt'
          }
        ];
      }

      return prev;
    }, []);

  if (fieldsErrors.length > 1) {
    return [
      ...debtErrors,
      {
        listMessage: i18n('roboAdvice.errors.missingFieldsDebtSection'),
        alertMessage: i18n('roboAdvice.errors.missingFieldsDebtSection'),
        elementId:
          clientType === ClientTypes.company
            ? 'company-assets'
            : 'person-assets'
      }
    ];
  }

  return [...debtErrors, ...fieldsErrors];
};
const getLiquidityErrors = ({
  roboAdviceFormState,
  financialSituationConfig,
  i18n,
  errorMessagesEnabled
}): ErrorMessage[] => {
  const liquidityErrors: ErrorMessage[] = [];
  const clientType = roboAdviceFormState?.values?.clientInformation?.clientType;

  const { liquidity } = financialSituationConfig;

  const liquidityFields = liquidity[clientType] || [];

  if (!errorMessagesEnabled) {
    return liquidityErrors;
  }

  const key =
    clientType === ClientTypes.company
      ? 'companyFinancialSituation'
      : 'personFinancialSituation';

  liquidityFields
    .filter(
      ({ enabled, required, id }) =>
        enabled && (required || id === 'expectingFinancialChangesNote')
    )
    .forEach(field => {
      const value = view(lensPath([key, field.id]), roboAdviceFormState.values);
      const hasValue =
        value && Array.isArray(value)
          ? value.some(d => filterNil(d?.value))
          : value !== undefined && value !== null;

      if (
        !hasValue &&
        (field.id !== 'expectingFinancialChangesNote' ||
          isFieldProgressCalculationRequired(
            field.expectingFinancialNoteRule,
            roboAdviceFormState.values
          ))
      ) {
        liquidityErrors.push({
          listMessage: i18n('roboAdvice.errors.missingInput').replace(
            '{0}',
            getTranslation(field.label)
          ),
          alertMessage: i18n('roboAdvice.errors.missingInputAlert').replace(
            '{0}',
            getTranslation(field.label)
          ),
          elementId:
            clientType === ClientTypes.company
              ? 'company-liquidity'
              : 'person-liquidity'
        });
      }
    });

  return liquidityErrors;
};
const getAccountingFiguresErrors = ({
  roboAdviceFormState,
  financialSituationConfig,
  i18n,
  errorMessagesEnabled
}): ErrorMessage[] => {
  const accountingFiguresErrors: ErrorMessage[] = [];
  const clientType = roboAdviceFormState?.values?.clientInformation?.clientType;

  const { accountingFigures } = financialSituationConfig;

  const accountingFiguresFields = accountingFigures || [];

  if (!errorMessagesEnabled) {
    return accountingFiguresErrors;
  }

  if (clientType === ClientTypes.company) {
    accountingFiguresFields
      .filter(({ enabled, required }) => enabled && required)
      .forEach(field => {
        const value = view(
          lensPath(['companyFinancialSituation', field.id]),
          roboAdviceFormState.values
        );
        const hasValue =
          value && Array.isArray(value)
            ? value.some(d => filterNil(d?.value))
            : value !== undefined && value !== null;
        if (!hasValue) {
          accountingFiguresErrors.push({
            listMessage: i18n('roboAdvice.errors.missingInput').replace(
              '{0}',
              getTranslation(field.label)
            ),
            alertMessage: i18n('roboAdvice.errors.missingInputAlert').replace(
              '{0}',
              getTranslation(field.label)
            ),
            elementId: 'company-accounting-figures'
          });
        }
      });
  }

  return accountingFiguresErrors;
};

type GetAmountForAdviceErrorsParams = {
  roboAdviceFormState: { values: AdviceSessionFormValues };
  financialSituationConfig: ReturnType<
    typeof useCustomerConfig
  >['roboAdviceForm']['financialSituation'];
  currentSum: number;
  i18n: ReturnType<typeof useI18n>;
  errorMessagesEnabled: boolean;
  amountForAdviceAssetsWithValue: ReturnType<
    typeof useAmountForAdviceAssetsWithValue
  >;
};

const getAmountForAdviceErrors = ({
  roboAdviceFormState,
  financialSituationConfig,
  currentSum,
  i18n,
  errorMessagesEnabled,
  amountForAdviceAssetsWithValue
}: GetAmountForAdviceErrorsParams): ErrorMessage[] => {
  const amountForAdviceErrors: ErrorMessage[] = [];
  const clientType = roboAdviceFormState?.values?.clientInformation?.clientType;
  const clientTypeKey =
    clientType === ClientTypes.person
      ? 'personFinancialSituation'
      : 'companyFinancialSituation';

  const isAmountForAdviceVisible =
    financialSituationConfig.isAmountForAdviceEnabled &&
    financialSituationConfig.assets[clientType]?.some(
      ({ showInAmountForAdvice, id }) =>
        showInAmountForAdvice &&
        !isNil(roboAdviceFormState.values[clientTypeKey]?.[id]?.[0]?.value)
    );

  if (!errorMessagesEnabled || !isAmountForAdviceVisible) {
    return [];
  }

  if (currentSum === 0) {
    amountForAdviceErrors.push({
      listMessage: i18n('roboAdvice.errors.zeroAmountForAdvice'),
      alertMessage: i18n('roboAdvice.errors.zeroAmountForAdviceAlert'),
      elementId: 'amountForAdviceContainer'
    });
  }

  const areAllAmountForAdviceAssetClassesFilled =
    amountForAdviceAssetsWithValue.every(({ id }) => {
      return roboAdviceFormState?.values?.[clientTypeKey]?.[id]?.every(
        ({ assetClass, value, toAdvisory }) =>
          !!assetClass || !value || !toAdvisory
      );
    });

  if (
    financialSituationConfig.taggingOfAssetClassesIsMandatory &&
    !areAllAmountForAdviceAssetClassesFilled
  ) {
    amountForAdviceErrors.push({
      listMessage: i18n(
        'roboAdvice.errors.amountForAdvice.missingAssetClassForAllAssets'
      ),
      alertMessage: i18n(
        'roboAdvice.errors.amountForAdvice.missingAssetClassForAllAssetsAlert'
      ),
      elementId: 'amountForAdviceContainer'
    });
  }

  return amountForAdviceErrors;
};

const getAmountForAdviceAdvisoryNotesErrors = ({
  roboAdviceFormState,
  financialSituationConfig,
  amountForAdviceAdvisorNotesConfig,
  i18n,
  errorMessagesEnabled
}): ErrorMessage[] => {
  const clientType = roboAdviceFormState?.values?.clientInformation?.clientType;
  const clientTypeKey =
    clientType === ClientTypes.person
      ? 'personFinancialSituation'
      : 'companyFinancialSituation';
  const areAnyFieldsFilledForAmountForAdvice = financialSituationConfig.assets[
    clientType
  ]?.some(
    ({ showInAmountForAdvice, id }) =>
      showInAmountForAdvice &&
      !isNil(roboAdviceFormState.values[clientTypeKey]?.[id]?.[0]?.value)
  );

  if (
    !errorMessagesEnabled ||
    !amountForAdviceAdvisorNotesConfig?.enabled ||
    !amountForAdviceAdvisorNotesConfig?.required ||
    !financialSituationConfig.isAmountForAdviceEnabled ||
    !areAnyFieldsFilledForAmountForAdvice ||
    roboAdviceFormState?.values?.amountForAdviceAdvisorNotes?.length
  ) {
    return [];
  } else {
    return [
      {
        listMessage: i18n('roboAdvice.errors.missingAdvisorNotes').replace(
          '{0}',
          i18n('financialSituation.amountForAdvice.advisorNotes.header')
        ),
        alertMessage: i18n('roboAdvice.errors.missingAdvisorNotesAlert'),
        elementId: 'amount-for-advice-advisor-notes'
      }
    ];
  }
};
