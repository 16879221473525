import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { FormSpy } from 'react-final-form';

import { form as roboAdviceForm } from '../../form/services/form';
import { useIsSessionReadOnly } from '../../main/services/selectors';
import { Page } from '../../shared/types';
import AdaptedValueMessage from './adaptedValueMessage';
import AdaptedValueQuestionAlert from './adaptedValueQuestionAlert';
import { ReactComponent as InfoIcon } from 'assets/info.svg';
import { ReactComponent as LeafIcon } from 'assets/leaf.svg';
import { Layouts } from 'features/roboAdvice/shared/constants';
import AdvisorNotes from 'features/shared/components/advisorNotes';
import ConfigurableLabel from 'features/shared/components/configurableLabel';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography, FontWeights } from 'features/shared/constants/typography';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { BorderRadiusTypes } from 'features/sharedModules/customerConfig/constants';
import InputErrorMessage from 'features/sharedModules/errors/components/inputErrorMessage';
import { useErrorsStore } from 'features/sharedModules/errors/services/errorsStore';
import { SustainabilityErrors } from 'features/sharedModules/errors/types';
import FinalFormRadios from 'features/sharedModules/finalForm/components/radios';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const borderRadius = {
  [BorderRadiusTypes.rounded]: '25px',
  [BorderRadiusTypes.sharp]: '15px'
};

const useStyles = createUseStyles(theme => ({
  container: {
    maxWidth: 1200,
    marginBottom: Spacing.spacing04
  },
  titleBar: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    margin: `0 ${Spacing.spacing01}px`,
    fontSize: Typography.heading2.size,
    fontWeight: FontWeights.medium
  },
  description: {
    color: theme.secondaryColor,
    marginTop: Spacing.spacing01
  },
  error: {
    color: theme.errorNegativeColor
  },
  leafIcon: {
    fill: theme.accentColor
  },
  infoIcon: {
    fill: theme.secondaryColor,
    cursor: 'pointer',
    marginRight: Spacing.spacing01
  },
  radiosItem: {
    '& + &': {
      marginTop: Spacing.spacing01
    }
  },
  radiosContainer: {
    margin: `${Spacing.spacing02}px 0`,
    display: 'block'
  },
  hasError: {
    border: `2px solid ${theme.errorNegativeColor}`,
    padding: `${Spacing.spacing01}px ${Spacing.spacing01}px ${Spacing.spacing00}px `,
    borderRadius: borderRadius[theme.borderRadiusType]
  },
  advisorNotes: {
    marginTop: Spacing.spacing01
  },
  valueAdaptedErrors: {
    marginBottom: Spacing.spacing01
  },
  content: {
    marginLeft: Spacing.spacing02
  }
}));

type Props = {
  setIsModalOpen?: (val: boolean) => void;
  setModalContent?: (pages: Page[]) => void;
  setModalHeader?: (header: string) => void;
  errors: SustainabilityErrors;
};

const GenericAssessment = ({
  setIsModalOpen,
  setModalContent,
  setModalHeader,
  errors
}: Props) => {
  const classes = useStyles();
  const i18n = useI18n();
  const isSessionReadOnly = useIsSessionReadOnly();
  const {
    genericAssessment: {
      isCommentFieldRequired,
      isCommentFieldShown,
      isValueAdaptedRecorded
    } = {},
    educationalModals: { genericAssessment: genericAssessmentEducationalModal }
  } = useCustomerConfig()?.roboAdvice?.sustainability;
  const isErrorModeEnabled = useErrorsStore(state => state.isErrorModeEnabled);
  const [oldValue, setOldValue] = useState<boolean | undefined>();

  useEffect(() => {
    const { values } = roboAdviceForm.getState();
    setOldValue(values?.sustainability?.genericAssessment?.answer);
  }, []);

  const handleAfterChange = (value: boolean) => {
    if (value === false) {
      roboAdviceForm.batch(() => {
        [
          'sustainability.alignmentCriteria',
          'sustainability.preferenceCriteria',
          'sustainability.alignmentCriteriaTextFields',
          'sustainability.alignmentCriteriaValueAdapted',
          'sustainability.preferenceCriteriaAdvisorNotes',
          'sustainability.preferenceCriteriaValueAdapted',
          'sustainability.offModelQuestions',
          'sustainability.offModelQuestionsAdvisorNotes',
          'sustainability.offModelQuestionsValueAdapted'
        ].forEach(field => {
          roboAdviceForm.change(field as any, undefined);
        });
      });
    } else {
      roboAdviceForm.change(
        'sustainability.arePreferenceCriteriaValueAdaptedQuestionsVisible' as any,
        undefined
      );
    }

    if (oldValue !== undefined && value !== oldValue) {
      roboAdviceForm.change(
        'sustainability.genericAssessment.isValueAdaptedQuestionVisible' as any,
        true
      );
    }

    setOldValue(value);
  };

  return (
    <FormSpy subscription={{ values: true }}>
      {({ values }) => (
        <div
          className={classNames(classes.container, {
            [classes.hasError]:
              (errors.genericAssessment?.length ||
                errors.genericAssessmentValueAdapted?.length) &&
              isErrorModeEnabled
          })}
          id={'generic-assessment'}
        >
          <div className={classes.titleBar}>
            <LeafIcon className={classes.leafIcon} />
            <div className={classes.title}>
              <ConfigurableLabel
                label={i18n(
                  'roboAdvice.sustainability.genericAssessment.header'
                )}
                required
              />
            </div>
            {genericAssessmentEducationalModal?.enabled &&
              genericAssessmentEducationalModal?.pages &&
              genericAssessmentEducationalModal.pages.length > 0 && (
                <InfoIcon
                  className={classes.infoIcon}
                  onClick={
                    setIsModalOpen && setModalContent && setModalHeader
                      ? () => {
                          setModalContent(
                            genericAssessmentEducationalModal.pages
                          );
                          setModalHeader(
                            i18n(
                              'roboAdvice.sustainability.genericAssessment.header'
                            )
                          );
                          setIsModalOpen(true);
                        }
                      : () => {}
                  }
                />
              )}
            {isValueAdaptedRecorded &&
              values?.sustainability?.genericAssessment
                ?.isValueAdaptedAnswer === true && (
                <AdaptedValueMessage
                  hasCloseButton
                  onClose={() => {
                    roboAdviceForm.change(
                      'sustainability.genericAssessment.isValueAdaptedAnswer' as any,
                      false
                    );
                    roboAdviceForm.change(
                      'sustainability.genericAssessment.isValueAdaptedQuestionVisible' as any,
                      false
                    );
                  }}
                />
              )}
          </div>

          <div className={classes.content}>
            <div className={classes.description}>
              {i18n('roboAdvice.sustainability.genericAssessment.description')}
            </div>

            <FinalFormRadios
              name="sustainability.genericAssessment.answer"
              items={[
                {
                  activeValue: false,
                  label: i18n(
                    'roboAdvice.sustainability.genericAssessment.answerNegative'
                  )
                },
                {
                  activeValue: true,
                  label: i18n(
                    'roboAdvice.sustainability.genericAssessment.answerPositive'
                  )
                }
              ]}
              layout={Layouts.vertical}
              className={classes.radiosItem}
              sectionClassName={classes.radiosContainer}
              disabled={isSessionReadOnly}
              afterChange={val => handleAfterChange(val as boolean)}
            />

            {errors?.genericAssessmentValueAdapted &&
              errors?.genericAssessmentValueAdapted?.length > 0 && (
                <div className={classes.valueAdaptedErrors}>
                  {errors?.genericAssessmentValueAdapted?.map(error => (
                    <InputErrorMessage
                      message={error.alertMessage}
                      key={error.alertMessage}
                    />
                  ))}
                </div>
              )}

            {isValueAdaptedRecorded &&
              values?.sustainability?.genericAssessment
                ?.isValueAdaptedQuestionVisible && (
                <AdaptedValueQuestionAlert
                  id="generic-assessment-question-alert"
                  hasError={
                    (errors?.genericAssessmentValueAdapted?.length ?? 0) > 0 &&
                    isErrorModeEnabled
                  }
                  onClickNo={() => {
                    setOldValue(
                      values?.sustainability?.genericAssessment?.answer
                    );

                    roboAdviceForm.batch(() => {
                      roboAdviceForm.change(
                        'sustainability.genericAssessment.isValueAdaptedAnswer' as any,
                        false
                      );
                      roboAdviceForm.change(
                        'sustainability.genericAssessment.isValueAdaptedQuestionVisible' as any,
                        false
                      );
                    });
                  }}
                  onClickYes={() => {
                    setOldValue(
                      values?.sustainability?.genericAssessment?.answer
                    );

                    roboAdviceForm.batch(() => {
                      roboAdviceForm.change(
                        'sustainability.genericAssessment.isValueAdaptedAnswer' as any,
                        true
                      );
                      roboAdviceForm.change(
                        'sustainability.genericAssessment.isValueAdaptedQuestionVisible' as any,
                        false
                      );
                    });
                  }}
                />
              )}

            {isCommentFieldShown && (
              <AdvisorNotes
                id={'generic-assessment-comment'}
                name={'sustainability.genericAssessment.comment'}
                minRows={2}
                disabled={isSessionReadOnly}
                required={isCommentFieldRequired}
                errors={errors.genericAssessmentComment}
                className={classes.advisorNotes}
                customPlaceholder={i18n(
                  'roboAdvice.sustainability.genericAssessment.advisorNotesPlaceholder'
                )}
              />
            )}
          </div>
        </div>
      )}
    </FormSpy>
  );
};

export default GenericAssessment;
