import axios, { CancelTokenSource } from 'axios';
import { isNil } from 'ramda';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  FundFees,
  useCustomPortfolioStore
} from '../../services/customPortfolioStore';
import { getUserPageLanguage } from 'features/pageLanguage/main/components/usePageLanguage';
import { createAnalyzeUniverseCost } from 'features/roboAdvice/adviceSession/shared/api';
import { Goal } from 'features/roboAdvice/adviceSession/shared/services/goalsStore';
import { getQAuthAccessToken } from 'features/shared/api';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

export const useReadFundManagementFees = () => {
  const i18n = useI18n();
  const dispatch = useDispatch();
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const cancelTokenSourceRef = useRef<CancelTokenSource>();
  const customPortfolioStore = useCustomPortfolioStore();

  const readFundManagementFees = async (selectedGoal: Goal) => {
    if (
      selectedGoal.data.monthlyDeposit ===
        customPortfolioStore.fundsFees.monthlyDeposit &&
      selectedGoal.data.firstDeposit ===
        customPortfolioStore.fundsFees.firstDeposit &&
      selectedGoal.data.productPlatformNamespace ===
        customPortfolioStore.fundsFees.productPlatformNamespace &&
      selectedGoal.data.internalHolding ===
        customPortfolioStore.fundsFees.internalHolding &&
      selectedGoal.data.followUpAddOn ===
        customPortfolioStore.fundsFees.followUpAddOn &&
      selectedGoal.data.followUpWithdrawal ===
        customPortfolioStore.fundsFees.followUpWithdrawal
    )
      return;

    if (!isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    const payload = {
      savingsplan: {
        monthly_savings: selectedGoal.data.monthlyDeposit,
        starting_capital:
          (selectedGoal.data.firstDeposit ?? 0) +
          (selectedGoal.data.internalHolding ?? 0) +
          ((selectedGoal.data.followUpAddOn ?? 0) -
            (selectedGoal.data.followUpWithdrawal ?? 0))
      }
    };

    if (
      (payload.savingsplan.monthly_savings ?? 0) <= 0 &&
      payload.savingsplan.starting_capital <= 0
    ) {
      return;
    }

    try {
      const qAuthAccessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      const responseAnalyzeUniverseCost = await createAnalyzeUniverseCost(
        qAuthAccessToken,
        cancelTokenSource.token,
        payload,
        {
          namespace_id: selectedGoal.data.productPlatformNamespace,
          language: getUserPageLanguage()
        }
      );

      const fundsFees: FundFees[] = (
        responseAnalyzeUniverseCost?.data?.summary?.instruments || []
      ).map(
        ({
          Category,
          FundStandardName,
          ISIN,
          Name,
          Ticker,
          fund_management_fee
        }) => ({
          category: Category,
          fundStandardName: FundStandardName,
          ISIN,
          name: Name,
          ticker: Ticker,
          fundManagementFee: fund_management_fee
        })
      );

      customPortfolioStore.setFundsFees({
        data: fundsFees,
        monthlyDeposit: selectedGoal.data.monthlyDeposit,
        firstDeposit: selectedGoal.data.firstDeposit,
        productPlatformNamespace: selectedGoal.data.productPlatformNamespace,
        internalHolding: selectedGoal.data.internalHolding,
        followUpAddOn: selectedGoal.data.followUpAddOn,
        followUpWithdrawal: selectedGoal.data.followUpWithdrawal
      });
    } catch (error) {
      if (!axios.isCancel(error)) {
        dispatch(
          notificationActionCreators.showNotification({
            message: i18n(
              'roboAdvice.advisory.customPortfolio.readFundManagementFeesError'
            ),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  return readFundManagementFees;
};
