import classNames from 'classnames';
import * as R from 'ramda';
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  RoboAdviceClientPages,
  RoboAdviceClientsListPages
} from '../../../shared/constants';
import { clientsTable } from '../../clientsTable';
import { RoboAdviceClientsTableOrderKeys } from '../constants';
import { useClientsListStore } from '../services/clientsListStore';
import { useClients } from './useClients';
import { useDeleteClient } from './useDeleteClient';
import { useTakeOverClient } from './useTakeOverClient';
import CompanyIcon from 'features/shared/components/icon/companyIcon';
import PersonIcon from 'features/shared/components/icon/personIcon';
import TableRowMenu from 'features/shared/components/table/tableRowMenu.js';
import { ClientTypes, ClientType } from 'features/shared/constants/session';
import routeTemplates from 'features/shared/utils/routeTemplates';
import { defaultConfirmation } from 'features/sharedInstances/defaultConfirmation';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { useQuery } from 'features/sharedModules/reactRouter/components/useQuery';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';
import FavoriteIcon from 'features/sharedModules/table/components/favoriteIcon';
import TableComponent from 'features/sharedModules/table/components/table';
import TableBody from 'features/sharedModules/table/components/tableBody';
import TableBodyRow from 'features/sharedModules/table/components/tableBodyRow';

// Temporary solution until types added to TableRowMenu
const AnyTableRowMenu = TableRowMenu as any;

const useStyles = createUseStyles({
  isFavoriteCell: {
    verticalAlign: 'middle',
    lineHeight: '1',
    width: '60px'
  },
  typeCell: {
    verticalAlign: 'middle',
    lineHeight: '1',
    textAlign: 'center',
    minWidth: '70px'
  },
  actionsCell: {
    verticalAlign: 'middle',
    lineHeight: '1',
    textAlign: 'right',
    width: '80px'
  },
  emailCell: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '200px'
  }
});

const TypeIcon = ({ type }: { type: ClientType }) => {
  if (type === ClientTypes.person) {
    return <PersonIcon />;
  }

  if (type === ClientTypes.company) {
    return <CompanyIcon />;
  }

  return null;
};

const EmptyValuePlaceholder = () => (
  <span dangerouslySetInnerHTML={{ __html: '&mdash;' }} />
);

const Table = () => {
  const i18n = useI18n();
  const classes = useStyles();
  const history = useHistory();
  const query = useQuery();
  const deleteClient = useDeleteClient();
  const takeOverClient = useTakeOverClient();
  const clientsListStore = useClientsListStore();
  const clients = useClients();
  const defaultConfirmationStoreState =
    defaultConfirmation.useConfirmationStore.getState();
  const tableRef = React.useRef<any>();
  const tableContainerRef = React.useRef<any>();
  const [idClientMenuOpen, setIdClientMenuOpen] = React.useState<string | null>(
    null
  );
  const {
    tenantSettings: { hideClientList }
  } = useCustomerConfig();
  const { pageId } = useParams<{ pageId: string }>();

  const clientList =
    hideClientList &&
    pageId === RoboAdviceClientsListPages.allClients &&
    !clientsListStore.search
      ? []
      : clients;

  return (
    <TableComponent
      module={clientsTable}
      header={[
        {
          title: '',
          className: classes.isFavoriteCell
        },
        {
          title: i18n('roboAdvice.clientsList.clientsTable.type'),
          className: classes.typeCell
        },
        {
          title: i18n('roboAdvice.clientsList.clientsTable.name'),
          orderKey: RoboAdviceClientsTableOrderKeys.name,
          isOrderable: true
        },
        {
          title: i18n('roboAdvice.clientsList.clientsTable.email')
        },
        {
          title: i18n('roboAdvice.clientsList.clientsTable.advisor')
        },
        {
          title: '',
          className: classes.actionsCell
        }
      ]}
      isLoading={
        clientsListStore.isReadClientsPending ||
        clientsListStore.isDeleteClientPending ||
        clientsListStore.isTransferClientPending
      }
      forwardedTableRef={tableRef}
      forwardedTableContainerRef={tableContainerRef}
    >
      <TableBody
        noDataTitle={i18n('roboAdvice.clientsList.clientsTable.noClients')}
        cellsNumber={7}
      >
        {clientList.map(client => {
          const createMenuItems = ({ toggleMenu }) => {
            if (idClientMenuOpen && idClientMenuOpen !== client.id) {
              toggleMenu(false);
            }
            const menuItems = [
              {
                title: i18n('shared.delete'),
                onClick: e => {
                  e.preventDefault();

                  defaultConfirmationStoreState.open(
                    i18n(
                      'roboAdvice.client.adviceSessionsTable.deleteAdviceSessionConfirmationMessage'
                    ).replace('{0}', client.name),
                    () => {
                      deleteClient(client.id);
                    }
                  );

                  toggleMenu(false);
                }
              }
            ];

            if (!client.isOwnedByUser) {
              menuItems.push({
                title: i18n('shared.takeOver'),
                onClick: e => {
                  e.preventDefault();

                  defaultConfirmationStoreState.open(
                    i18n(
                      'roboAdvice.clientsList.clientsTable.takeOverClientConfirmationMessage'
                    ).replace('{0}', client.name),
                    () => {
                      takeOverClient(client.id);
                    }
                  );

                  toggleMenu(false);
                }
              });
            }

            return menuItems;
          };

          return (
            <TableBodyRow
              key={client.id}
              hoverEffect={true}
              onClick={() => {
                history.push(
                  routeTemplates.roboAdviceClient.build(
                    client.type,
                    client.id,
                    RoboAdviceClientPages.adviceSessions,
                    {
                      clientType: query.clientType,
                      clientId: query.clientId,
                      adviceSessionId: query.adviceSessionId
                    }
                  )
                );
              }}
            >
              {({ bodyRowCellClassName }) => (
                <>
                  <td
                    className={classNames(
                      bodyRowCellClassName,
                      classes.isFavoriteCell
                    )}
                  >
                    <FavoriteIcon
                      id={client.id}
                      onClick={event => {
                        event.stopPropagation();
                      }}
                    />
                  </td>
                  <td
                    className={classNames(
                      bodyRowCellClassName,
                      classes.typeCell
                    )}
                  >
                    <TypeIcon type={client.type} />
                  </td>
                  <td className={bodyRowCellClassName}>{client.name}</td>
                  <td
                    className={classNames(
                      bodyRowCellClassName,
                      classes.emailCell
                    )}
                  >
                    {client.email || <EmptyValuePlaceholder />}
                  </td>
                  <td className={bodyRowCellClassName}>
                    {client.advisorName || <EmptyValuePlaceholder />}
                  </td>
                  <td
                    className={classNames(
                      bodyRowCellClassName,
                      classes.actionsCell
                    )}
                  >
                    <AnyTableRowMenu
                      tableRef={tableRef}
                      tableContainerRef={tableContainerRef}
                      createMenuItems={createMenuItems}
                      setIdOpenModal={setIdClientMenuOpen}
                      currentId={client.id}
                    />
                  </td>
                </>
              )}
            </TableBodyRow>
          );
        })}
      </TableBody>
    </TableComponent>
  );
};

export default Table;
