import { isNil } from 'ramda';
import { useState } from 'react';
import { Form } from 'react-final-form';

import FundTableSection from './fundTableSection';
import OverrideCost from './overrideCost';
import { OverridePlatformFeeModes } from 'defaults/defaultRoboAdviceForm';
import { costForm } from 'features/roboAdvice/adviceSession/proposal/services/costForm';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  root: {
    width: '100%'
  },
  tableHeader: {
    paddingTop: '30px',
    paddingBottom: '20px',
    textAlign: 'left'
  }
});

const FundsTables = ({
  fundsDetailsData,
  isOverrideFundEnabled = false,
  originalTableData
}) => {
  const classes = useStyles();
  const i18n = useI18n();
  const {
    roboAdviceForm: {
      proposal: {
        isOverrideFundManagementFeeEnabled,
        isOverrideFundTransactionCostEnabled,
        isOverrideFundPurchasingFeeEnabled,
        isOverrideFundReturnCommissionPaidToClientEnabled,
        isOverrideFundInsuranceCostEnabled,
        isOverrideFundOngoingFeesAdvisorNotesRequired,
        isOverrideFundOneTimeFeesAdvisorNotesRequired,
        overridePlatformFeeMode
      }
    }
  } = useCustomerConfig();

  const [isOngoingFeesEditModeActive, setIsOngoingFeesEditModeActive] =
    useState(false);
  const [isOneTimeFeesEditModeActive, setIsOneTimeFeesEditModeActive] =
    useState(false);

  const isAnyEditModeActive =
    isOngoingFeesEditModeActive || isOneTimeFeesEditModeActive;

  const overrideOngoingFeesFundCosts = [
    isOverrideFundManagementFeeEnabled &&
      fundsDetailsData.ongoingFees.some(
        fund => !isNil(fund.fundManagementFee)
      ) &&
      'fundManagementFee',
    isOverrideFundTransactionCostEnabled &&
      fundsDetailsData.ongoingFees.some(
        fund => !isNil(fund.fundTransactionCost)
      ) &&
      'fundTransactionCost',
    isOverrideFundReturnCommissionPaidToClientEnabled &&
      fundsDetailsData.ongoingFees.some(
        fund => !isNil(fund.fundReturnCommissionPaidToClient)
      ) &&
      'fundReturnCommissionPaidToClient',
    isOverrideFundInsuranceCostEnabled &&
      fundsDetailsData.ongoingFees.some(
        fund => !isNil(fund.fundInsuranceCost)
      ) &&
      'fundInsuranceCost',
    overridePlatformFeeMode === OverridePlatformFeeModes.instrument &&
      fundsDetailsData.ongoingFees.some(fund => !isNil(fund.platformFee)) &&
      'platformFee'
  ].filter(cost => !!cost);

  const overrideOneTimeFeesFundCosts = [
    isOverrideFundPurchasingFeeEnabled &&
      fundsDetailsData.oneTimeFees.some(fund => !isNil(fund.fundPurchaseFee)) &&
      'fundPurchaseFee'
  ].filter(cost => !!cost);

  return (
    <Form form={costForm} onSubmit={() => {}}>
      {() => (
        <div className={classes.root}>
          <FundTableSection
            sectionHeader={i18n('costChart.costTable.ongoingFees')}
            headerTitles={[
              i18n('roboAdvice.proposal.cost.fund'),
              i18n('costChart.costTable.fundManagementFee'),
              i18n('costChart.costTable.fundTransactionCost'),
              i18n('costChart.costTable.fundReturnCommissionPaidToClient'),
              i18n('costChart.costTable.performanceFee'),
              i18n('costChart.costTable.platformFee'),
              i18n('costChart.costTable.insuranceCost'),
              i18n('costChart.costTable.custodyFee'),
              i18n('roboAdvice.proposal.cost.totalExpenseRatio')
            ]}
            dataKeys={[
              'fund',
              'fundManagementFee',
              'fundTransactionCost',
              'fundReturnCommissionPaidToClient',
              'performanceFee',
              'platformFee',
              'insuranceCost',
              'custodyFee',
              'totalExpenseRatio'
            ]}
            tableData={fundsDetailsData.ongoingFees}
            highlightLastRow
            overrideFundCosts={overrideOngoingFeesFundCosts}
            isEditModeActive={isOngoingFeesEditModeActive}
            setIsEditModeActive={setIsOngoingFeesEditModeActive}
            isOverrideFundEnabled={isOverrideFundEnabled}
            originalTableData={originalTableData?.ongoingFees}
            isAnyEditModeActive={isAnyEditModeActive}
          />
          {isOngoingFeesEditModeActive &&
            overrideOngoingFeesFundCosts.length > 0 && (
              <OverrideCost
                advisorNotesId="override-fund-ongoing-fees-advisor-notes"
                advisorNotesName="overrideFundOngoingFeesAdvisorNotes"
                errorKey="overrideFundCosts"
                isOverrideAdvisorNotesRequired={
                  isOverrideFundOngoingFeesAdvisorNotesRequired
                }
                setIsEditModeActive={setIsOngoingFeesEditModeActive}
              />
            )}
          <FundTableSection
            sectionHeader={i18n('costChart.costTable.custodyFee')}
            headerTitles={[
              i18n('roboAdvice.proposal.cost.fund'),
              i18n('costChart.costTable.custodyFee')
            ]}
            dataKeys={['fund', 'fundCustodyFee']}
            tableData={fundsDetailsData.custodyFees}
            highlightLastRow
          />
          <FundTableSection
            sectionHeader={i18n('roboAdvice.proposal.cost.oneTimeFees')}
            headerTitles={[
              i18n('roboAdvice.proposal.cost.fund'),
              i18n('costChart.costTable.fundPurchaseFee'),
              i18n('costChart.costTable.advisoryPurchaseFee'),
              i18n('costChart.costTable.advisoryOnboardingFee')
            ]}
            tableData={fundsDetailsData.oneTimeFees}
            dataKeys={[
              'fund',
              'fundPurchaseFee',
              'advisoryPurchaseFee',
              'advisoryOnboardingFee'
            ]}
            highlightLastRow
            overrideFundCosts={overrideOneTimeFeesFundCosts}
            isEditModeActive={isOneTimeFeesEditModeActive}
            setIsEditModeActive={setIsOneTimeFeesEditModeActive}
            isOverrideFundEnabled={isOverrideFundEnabled}
            originalTableData={originalTableData?.oneTimeFees}
            isAnyEditModeActive={isAnyEditModeActive}
          />
          {isOneTimeFeesEditModeActive &&
            overrideOneTimeFeesFundCosts.length > 0 && (
              <OverrideCost
                advisorNotesId="override-fund-one-time-fees-advisor-notes"
                advisorNotesName="overrideFundOneTimeFeesAdvisorNotes"
                errorKey="overrideFundCosts"
                isOverrideAdvisorNotesRequired={
                  isOverrideFundOneTimeFeesAdvisorNotesRequired
                }
                setIsEditModeActive={setIsOneTimeFeesEditModeActive}
              />
            )}
          <FundTableSection
            sectionHeader={i18n(
              'costChart.costTable.returnCommissionPaidToAdvisor'
            )}
            headerTitles={[
              i18n('roboAdvice.proposal.cost.fund'),
              i18n('costChart.costTable.fundReturnCommissionPaidToAdvisor')
            ]}
            tableData={fundsDetailsData.returnCommissionPaidToAdvisor}
            dataKeys={['fund', 'fundReturnCommissionPaidToAdvisor']}
            highlightLastRow
          />
        </div>
      )}
    </Form>
  );
};

export default FundsTables;
