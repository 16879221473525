import { formatNumber } from '../../shared/utils/number';

const getForYearAggregatedCostEffect = (item, cultureCode) => [
  {
    field: 'costChart.yearsTable.deposits',
    value: formatNumber(cultureCode, item.aggregatedCostEffect.deposits, 0, 0)
  },
  {
    field: 'costChart.yearsTable.expValueBeforeCost',
    value: formatNumber(
      cultureCode,
      item.aggregatedCostEffect.valueBeforeCost,
      0,
      0
    )
  },
  {
    field: 'costChart.yearsTable.expValueAfterCost',
    value: formatNumber(
      cultureCode,
      item.aggregatedCostEffect.valueAfterCost,
      0,
      0
    )
  },
  {
    field: 'costChart.yearsTable.reductionInReturn',
    value: formatNumber(
      cultureCode,
      item.aggregatedCostEffect.reductionInReturn,
      0,
      0
    )
  },
  {
    field: 'costChart.yearsTable.reductionInReturnPercent',
    percent: formatNumber(
      cultureCode,
      item.aggregatedCostEffect.reductionInReturnPercent,
      2,
      2
    )
  },
  {
    field: 'costChart.yearsTable.expValueAfterCostPercent',
    percent: formatNumber(
      cultureCode,
      item.aggregatedCostEffect.returnAfterCost,
      2,
      2
    )
  },
  {
    field: 'costChart.yearsTable.expectedReturn',
    value: formatNumber(
      cultureCode,
      item.aggregatedCostEffect.expectedReturn,
      0,
      0
    )
  }
];

const getForYearAggregatedOngoingFees = (item, cultureCode) => [
  {
    field: 'costChart.costTable.fundManagementFee',
    value: formatNumber(
      cultureCode,
      item.aggregatedOngoingFees.fundManagementFee,
      0,
      0
    ),
    percent: formatNumber(
      cultureCode,
      item.aggregatedOngoingFeesPercent.fundManagementFee,
      2,
      2
    )
  },
  {
    field: 'costChart.costTable.fundReturnCommissionPaidToClient',
    value: formatNumber(
      cultureCode,
      item.aggregatedOngoingFees.fundReturnCommissionPaidToClient,
      0,
      0
    ),
    percent: formatNumber(
      cultureCode,
      item.aggregatedOngoingFeesPercent.fundReturnCommissionPaidToClient,
      2,
      2
    )
  },
  {
    field: 'costChart.costTable.fundTransactionCost',
    value: formatNumber(
      cultureCode,
      item.aggregatedOngoingFees.fundTransactionCost,
      0,
      0
    ),
    percent: formatNumber(
      cultureCode,
      item.aggregatedOngoingFeesPercent.fundTransactionCost,
      2,
      2
    )
  },
  {
    field: 'costChart.costTable.performanceFee',
    value: formatNumber(
      cultureCode,
      item.aggregatedOngoingFees.performanceFee,
      0,
      0
    ),
    percent: formatNumber(
      cultureCode,
      item.aggregatedOngoingFeesPercent.performanceFee,
      2,
      2
    )
  },
  {
    field: 'costChart.costTable.platformFee',
    value: formatNumber(
      cultureCode,
      item.aggregatedOngoingFees.platformFee,
      0,
      0
    ),
    percent: formatNumber(
      cultureCode,
      item.aggregatedOngoingFeesPercent.platformFee,
      2,
      2
    )
  },
  {
    field: 'costChart.costTable.insuranceCost',
    value: formatNumber(
      cultureCode,
      item.aggregatedOngoingFees.insuranceCost,
      0,
      0
    ),
    percent: formatNumber(
      cultureCode,
      item.aggregatedOngoingFeesPercent.insuranceCost,
      2,
      2
    )
  },
  {
    field: 'costChart.costTable.custodyFee',
    value: formatNumber(
      cultureCode,
      item.aggregatedOngoingFees.custodyFee,
      0,
      0
    ),
    percent: formatNumber(
      cultureCode,
      item.aggregatedOngoingFeesPercent.custodyFee,
      2,
      2
    )
  },
  {
    field: 'costChart.costTable.totalPortfolioFee',
    value: formatNumber(
      cultureCode,
      item.aggregatedOngoingFees.totalPortfolioFee,
      0,
      0
    ),
    percent: formatNumber(
      cultureCode,
      item.aggregatedOngoingFeesPercent.totalPortfolioFee,
      2,
      2
    )
  }
];

const getForYearOneTimeFees = (item, cultureCode) => [
  {
    field: 'costChart.costTable.fundPurchaseFee',
    value: formatNumber(cultureCode, item.oneTimeFees.fundPurchaseFee, 0, 0),
    percent: formatNumber(
      cultureCode,
      item.oneTimeFeesPercent.fundPurchaseFee,
      2,
      2,
      100
    )
  },
  {
    field: 'costChart.costTable.advisoryPurchaseFee',
    value: formatNumber(
      cultureCode,
      item.oneTimeFees.advisoryPurchaseFee,
      0,
      0
    ),
    percent: formatNumber(
      cultureCode,
      item.oneTimeFeesPercent.advisoryPurchaseFee,
      2,
      2,
      100
    )
  }
];

const getForYearAggregatedReturnCommissionPaidToAdvisor = (
  item,
  cultureCode
) => [
  {
    field: 'costChart.costTable.returnCommissionPaidToAdvisor',
    value: formatNumber(
      cultureCode,
      item.aggregatedReturnCommissionPaidToAdvisor
        .returnCommissionPaidToAdvisor,
      0,
      0
    ),
    percent: formatNumber(
      cultureCode,
      item.aggregatedReturnCommissionPaidToAdvisorPercent
        .returnCommissionPaidToAdvisor,
      2,
      2,
      100
    )
  }
];

const getFundOngoingFees = (data, cultureCode, fractionDigits = 2) => ({
  fund: data.fund,
  fundManagementFee: formatNumber(
    cultureCode,
    data.fundManagementFee,
    fractionDigits,
    fractionDigits
  ),
  fundTransactionCost: formatNumber(
    cultureCode,
    data.fundTransactionCost,
    fractionDigits,
    fractionDigits
  ),
  fundReturnCommissionPaidToClient: formatNumber(
    cultureCode,
    data.fundReturnCommissionPaidToClient,
    fractionDigits,
    fractionDigits
  ),
  performanceFee: formatNumber(
    cultureCode,
    data.performanceFee,
    fractionDigits,
    fractionDigits
  ),
  platformFee: formatNumber(
    cultureCode,
    data.platformFee,
    fractionDigits,
    fractionDigits
  ),
  insuranceCost: formatNumber(
    cultureCode,
    data.insuranceCost,
    fractionDigits,
    fractionDigits
  ),
  custodyFee: formatNumber(
    cultureCode,
    data.custodyFee,
    fractionDigits,
    fractionDigits
  ),
  totalExpenseRatio: formatNumber(
    cultureCode,
    data.totalExpenseRatio,
    fractionDigits,
    fractionDigits
  )
});

const getFundOneTimeFees = (data, cultureCode, fractionDigits = 2) => ({
  fund: data.fund,
  fundPurchaseFee: formatNumber(
    cultureCode,
    data.fundPurchaseFee,
    fractionDigits,
    fractionDigits
  ),
  advisoryPurchaseFee: formatNumber(
    cultureCode,
    data.advisoryPurchaseFee,
    fractionDigits,
    fractionDigits
  ),
  advisoryOnboardingFee: formatNumber(
    cultureCode,
    data.advisoryOnboardingFee,
    fractionDigits,
    fractionDigits
  )
});

const getFundCustodyFees = (data, cultureCode, fractionDigits = 2) => ({
  fund: data.fund,
  fundCustodyFee: formatNumber(
    cultureCode,
    data.custodyFee,
    fractionDigits,
    fractionDigits
  )
});

const getFundReturnCommissionPaidToAdvisor = (
  data,
  cultureCode,
  fractionDigits = 2
) => ({
  fund: data.fund,
  fundReturnCommissionPaidToAdvisor: formatNumber(
    cultureCode,
    data.fundReturnCommissionPaidToAdvisor,
    fractionDigits,
    fractionDigits
  )
});

const getSummaryAnnualOngoingFees = (data, cultureCode) => [
  {
    field: 'costChart.costTable.fundManagementFee',
    percent: formatNumber(
      cultureCode,
      data.annualOngoingFees.fundManagementFee,
      2,
      2,
      100
    ),
    icon: 'plus'
  },
  {
    field: 'costChart.costTable.fundReturnCommissionPaidToClient',
    percent: formatNumber(
      cultureCode,
      data.annualOngoingFees.fundReturnCommissionPaidToClient,
      2,
      2,
      100
    ),
    icon: 'minus'
  },
  {
    field: 'costChart.costTable.fundTransactionCost',
    percent: formatNumber(
      cultureCode,
      data.annualOngoingFees.fundTransactionCost,
      2,
      2,
      100
    ),
    icon: 'plus'
  },
  {
    field: 'costChart.costTable.performanceFee',
    percent: formatNumber(
      cultureCode,
      data.annualOngoingFees.performanceFee,
      2,
      2,
      100
    ),
    icon: 'plus'
  },
  {
    name: 'platformFee',
    field: 'costChart.costTable.platformFee',
    percent: formatNumber(
      cultureCode,
      data.annualOngoingFees.platformFee,
      2,
      2,
      100
    ),
    icon: 'plus'
  },
  {
    field: 'costChart.costTable.insuranceCost',
    name: 'insuranceCost',
    percent: formatNumber(
      cultureCode,
      data.annualOngoingFees.insuranceCost,
      2,
      2,
      100
    ),
    icon: 'plus'
  },
  {
    name: 'custodyFeePercent',
    field: 'costChart.costTable.custodyFee',
    percent: formatNumber(
      cultureCode,
      data.annualOngoingFees.custodyFee,
      2,
      2,
      100
    ),
    icon: 'plus'
  },
  {
    field: 'costChart.costTable.totalPortfolioFee',
    percent: formatNumber(
      cultureCode,
      data.annualOngoingFees.totalPortfolioFee,
      2,
      2,
      100
    ),
    icon: 'equals'
  }
];

const getSummaryOneTimeFees = (data, cultureCode) => {
  return [
    {
      field: 'costChart.costTable.fundPurchaseFee',
      percent: formatNumber(
        cultureCode,
        data.oneTimeFees.fundPurchaseFee,
        2,
        2,
        100
      )
    },
    {
      name: 'advisoryOnboardingFee',
      field: 'costChart.costTable.advisoryOnboardingFee',
      percent: formatNumber(
        cultureCode,
        data.oneTimeFees.advisoryOnboardingFee,
        2,
        2,
        100
      )
    },
    {
      name: 'advisoryPurchasingFee',
      field: 'costChart.costTable.advisoryPurchaseFee',
      percent: formatNumber(
        cultureCode,
        data.oneTimeFees.advisoryPurchaseFee,
        2,
        2,
        100
      )
    }
  ];
};

const getSummaryAnnualReturnCommissionPaidToAdvisor = (data, cultureCode) => [
  {
    field: 'costChart.costTable.returnCommissionPaidToAdvisor',
    percent: formatNumber(
      cultureCode,
      data.annualReturnCommissionPaidToAdvisor.returnCommissionPaidToAdvisor,
      2,
      2,
      100
    )
  }
];

const getSummaryCustodyFees = (data, cultureCode) => [
  {
    name: 'custodyFeeAmount',
    field: 'costChart.costTable.custodyFee',
    value: formatNumber(cultureCode, data.custodyFees.custodyFeeAmount, 0, 0)
  }
];

export default {
  getForYearAggregatedCostEffect,
  getForYearAggregatedOngoingFees,
  getForYearOneTimeFees,
  getForYearAggregatedReturnCommissionPaidToAdvisor,
  getFundOngoingFees,
  getFundOneTimeFees,
  getFundCustodyFees,
  getFundReturnCommissionPaidToAdvisor,
  getSummaryAnnualOngoingFees,
  getSummaryOneTimeFees,
  getSummaryAnnualReturnCommissionPaidToAdvisor,
  getSummaryCustodyFees
};
