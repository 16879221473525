import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { usePageStore as useProposalPageStore } from '../../proposal/services/pageStore';
import { NUMBER_OF_ALLOWED_STATISTICS } from '../../shared/constants';
import { getHistoricalReturnStatisticsData } from '../../shared/services/historicalReturnStatisticsSelectors';
import { PortfolioIds } from '../services/constants';
import { usePageStore as useAdvisoryPageStore } from '../services/pageStore';
import BarChart from './barChart';
import BenchmarkIcon from './benchmarkIcon';
import {
  useReadHistoricalReturnData,
  useHistoricalReturnChartStore
} from './useReadHistoricalReturnData';
import HistoricalReturnStatistics from 'features/roboAdvice/adviceSession/shared/components/historicalReturnStatistics';
import {
  PageStatuses,
  useReadDataListener
} from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';
import { Goal } from 'features/roboAdvice/adviceSession/shared/services/goalsStore';
import HistoricalReturnChartCardVisual from 'features/shared/analyticsComponents/historicalReturnChart/visual';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography } from 'features/shared/constants/typography';
import sessionSelectors from 'features/shared/services/session/selectors';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  buttonGroup: {
    justifyContent: 'center'
  },
  statistics: {
    fontSize: Typography.heading2.size,
    lineHeight: Typography.heading2.lineHeight,
    margin: `${Spacing.spacing04}px 0 ${Spacing.spacing01}px`,
    textAlign: 'left'
  }
});

type Props = {
  isSelected?: boolean;
  portfolioIds: typeof PortfolioIds[keyof typeof PortfolioIds][];
  selectedGoal: Goal;
};

const HistoricalReturn = ({
  isSelected,
  selectedGoal,
  portfolioIds
}: Props) => {
  const chartStoreData = useHistoricalReturnChartStore();
  const classes = useStyles();
  const cultureCode: string = useSelector(
    sessionSelectors.getCurrentUserCultureCode
  );
  const i18n = useI18n();
  const [activeItem, setActiveItem] = useState({
    statusKey: 'readHistoricalReturn3MChartData',
    timeRangeChartDataKey: '3M'
  });
  const { pageStatuses: advisoryPageStatuses } = useAdvisoryPageStore();
  const { pageStatuses: proposalPageStatuses } = useProposalPageStore();
  const {
    advisoryComponents: {
      historicalReturnCompareWithBenchmark,
      historicalReturnShowBarCharts,
      statisticsTableEnabled
    },
    analyticsComponents: { historicalReturnStatsItems }
  } = useCustomerConfig();

  const getItemStatus = useCallback(
    item => advisoryPageStatuses[item.statusKey],
    [advisoryPageStatuses]
  );

  useReadDataListener(
    advisoryPageStatuses.readHistoricalReturn3MChartData,
    !!isSelected &&
      activeItem.statusKey === 'readHistoricalReturn3MChartData' &&
      proposalPageStatuses.readPortfolioChartData === PageStatuses.succeed,
    useReadHistoricalReturnData(
      'readHistoricalReturn3MChartData',
      '3M',
      selectedGoal
    )
  );

  useReadDataListener(
    advisoryPageStatuses.readHistoricalReturnYTDChartData,
    !!isSelected &&
      activeItem.statusKey === 'readHistoricalReturnYTDChartData' &&
      proposalPageStatuses.readPortfolioChartData === PageStatuses.succeed,
    useReadHistoricalReturnData(
      'readHistoricalReturnYTDChartData',
      'YTD',
      selectedGoal
    )
  );

  useReadDataListener(
    advisoryPageStatuses.readHistoricalReturn1YChartData,
    !!isSelected &&
      activeItem.statusKey === 'readHistoricalReturn1YChartData' &&
      proposalPageStatuses.readPortfolioChartData === PageStatuses.succeed,
    useReadHistoricalReturnData(
      'readHistoricalReturn1YChartData',
      '1Y',
      selectedGoal
    )
  );

  useReadDataListener(
    advisoryPageStatuses.readHistoricalReturn3YChartData,
    !!isSelected &&
      activeItem.statusKey === 'readHistoricalReturn3YChartData' &&
      proposalPageStatuses.readPortfolioChartData === PageStatuses.succeed,
    useReadHistoricalReturnData(
      'readHistoricalReturn3YChartData',
      '3Y',
      selectedGoal
    )
  );

  useReadDataListener(
    advisoryPageStatuses.readHistoricalReturn5YChartData,
    !!isSelected &&
      activeItem.statusKey === 'readHistoricalReturn5YChartData' &&
      proposalPageStatuses.readPortfolioChartData === PageStatuses.succeed,
    useReadHistoricalReturnData(
      'readHistoricalReturn5YChartData',
      '5Y',
      selectedGoal
    )
  );

  useReadDataListener(
    advisoryPageStatuses.readHistoricalReturn10YChartData,
    !!isSelected &&
      (activeItem.statusKey === 'readHistoricalReturn10YChartData' ||
        (historicalReturnCompareWithBenchmark &&
          historicalReturnShowBarCharts)) &&
      proposalPageStatuses.readPortfolioChartData === PageStatuses.succeed,
    useReadHistoricalReturnData(
      'readHistoricalReturn10YChartData',
      '10Y',
      selectedGoal
    )
  );

  const statisticsData =
    statisticsTableEnabled &&
    getHistoricalReturnStatisticsData(
      chartStoreData.chartData[activeItem.timeRangeChartDataKey]?.filter(
        ({ goalId }) => portfolioIds.includes(goalId)
      ),
      historicalReturnStatsItems,
      NUMBER_OF_ALLOWED_STATISTICS
    );

  return (
    <>
      <HistoricalReturnChartCardVisual
        buttonGroupClassName={classes.buttonGroup}
        activeItem={activeItem}
        setActiveItem={setActiveItem}
        cultureCode={cultureCode}
        chartStoreChartData={chartStoreData.chartData}
        selectedGoalsIds={portfolioIds}
        getItemStatus={getItemStatus}
        benchmarkIcon={
          <BenchmarkIcon
            timeRangeChartDataKey={activeItem.timeRangeChartDataKey}
          />
        }
      />

      {historicalReturnCompareWithBenchmark &&
        historicalReturnShowBarCharts && <BarChart />}

      {statisticsTableEnabled &&
        statisticsData &&
        statisticsData.data.length > 0 && (
          <>
            <div className={classes.statistics}>
              {i18n('shared.statistics')}
            </div>

            <HistoricalReturnStatistics
              data={statisticsData.data}
              headerNames={statisticsData.names}
            />
          </>
        )}
    </>
  );
};

export default HistoricalReturn;
